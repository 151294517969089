<template>
  <div id="login-page" class="login">
    <div class="login-left">
      <div class="img_one"></div>
      <div class="img_two"></div>
      <div class="main-title">Hello,<td/>欢迎来到登录认证平台</div>
      <div class="img_three"></div>
      <div class="main-bottom">
        <div class="img-four"></div>
        <div class="title">ShouDianYun</div>
      </div>
    </div>
    <div class="login-right">
      <div class="login_form_div">
        <div class="login_div_top">登录认证</div>
        <div class="login_div_right">
          <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm login_form"
          >
            <div class="div_row" style="margin-top: 41px;">
              <div class="login_input">
                <div class="item_div">
                  <div class="form-item">账号</div>
                  <el-form-item prop="phoneNo">
                    <el-input
                        v-model="ruleForm.phoneNo"
                        prefix-icon="el-icon-user"
                        autofocus
                        clearable
                        placeholder="请输手机号"
                        maxlength="11"
                        show-word-limit
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div  class="div_row">
              <div class="login_input">
                <div class="item_div">
                  <div class="form-item">验证码</div>
                  <el-form-item label="" prop="smsCode">
                    <div style="display: flex;justify-content: space-between;" class="yzm-box">
                      <el-input
                          v-model="ruleForm.smsCode"
                          prefix-icon="el-icon-key"
                          style="width: 280px;"
                          type="text"
                          clearable
                          placeholder="请输入验证码"
                          @keydown.native.enter="loginPlay"
                      />
                      <el-button
                          size="small"
                          :loading="!can_get_code1"
                          class="btn_yzm"
                          @click="getCode"
                      >
                        <span v-if="!can_get_code1">{{ can_get_code_nub1 }}S重试</span>
                        <span v-else>获取验证码</span>
                      </el-button>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
<!--            <div class="div_row">
              <div class="login_input">
                <div class="item_div">
                  <div class="form-item">密码</div>
                  <el-form-item prop="password">
                    <el-input
                        v-model="ruleForm.password"
                        prefix-icon="el-icon-key"
                        autofocus
                        show-password
                        clearable
                        placeholder="请输入密码"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>-->
          </el-form>
          <div>
            <div class="btn login_btn">
              <el-button
                  class="btn_login"
                  native-type="submit"
                  style="width: 100%"
                  @click="loginPlay"
              >登 录
              </el-button>
            </div>
          </div>

        </div>
      </div>

<!--      <div class="login-right-foot">四川川能智网实业有限公司</div>-->
    </div>

    <el-dialog
        title="图形验证码"
        :visible.sync="dialogVisible1"
        width="300px"
        :before-close="handleClose">
      <el-form ref="imgVerifyAForm" :model="imgVerifyForm" :rules="{}" label-width="0">
          <div class="imgVerifyArea">
            <img :src="captchaImg" alt="" style="margin-bottom: 10px">
            <el-form-item
                style="display: flex;justify-content: center"
                label=""
                prop="captchaCode"
                :rules="{
                        required: true,
                        message: '不能为空',
                        trigger: 'change'
                    }"
            >
              <el-input v-model="imgVerifyForm.captchaCode" placeholder="请输入上图数字"/>
            </el-form-item>
          </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading1" @click="submitVerifyForm('imgVerifyAForm')">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {getVerify, Login, smsCode} from "@/api/login";
import {Message} from "element-ui";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "login",
  data(){
    let checkPhone=(rule, value, callback) => {
        let check = /^((\+|00)86)?1[3-9]\d{9}$/
        if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        if(check.test(value)){
          return callback()
        }else {
          return callback(new Error('手机号格式错误'))
        }
      }
    }
    return {
      ruleForm:{},
      rules:{
        phoneNo:[{validator:checkPhone, trigger: 'blur'}],
        smsCode:[{required: true, message: '请输入验证码', trigger: 'blur'}]
        //username:[{required: true, message: '请输入账号', trigger: 'blur'}],
        //password:[{required: true, message: '请输入密码', trigger: 'blur'}]
      },
      can_get_code1: true,
      can_get_code_nub1: 60,
      dialogVisible1:false,
      captchaImg:'',
      imgVerifyForm:{
        captchaKey:'',//验证码key
        captchaCode:'',//验证码
      },
      loading1:false
    }
  },
  methods:{
      loginPlay(){
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            Login(this.ruleForm).then(res=>{
              this.$store.commit('SET_TOKEN',res.body)
              if(window.location.href.includes('isRedirect=true')){
                if(res.status===200){
                  let url = window.location.href.split('?')[1]
                  window.location.href = '/sso/oauth/authorize?'+url
                }
              }else {
                this.$router.push({path:'/client'})
              }

            })


          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
    getCode(){

      this.$refs.ruleForm.validateField('phoneNo',(error)=>{
        if(error!=='手机号不能为空'&&error!=='手机号格式错误'){

          getVerify().then(res=>{
            // console.log(res,'123');
            this.captchaImg = res.body.captchaImg
            this.imgVerifyForm.captchaKey = res.body.captchaKey
            this.dialogVisible1 = true
          })


        }
      })


    },
    handleClose(){
        this.dialogVisible1 = false
        this.captchaImg = ''
        this.imgVerifyForm={
            captchaKey:'',//验证码key
            captchaCode:'',//验证码
        }
        this.loading1 = false
    },
    submitVerifyForm(formName){
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading1 = true
            let _data ={phone:this.ruleForm.phoneNo,type:0,...this.imgVerifyForm}
            this.handleClose()
            smsCode(_data).then(()=>{
              this.loading1 = false
              Message({
                type:'success',
                message:'成功获取验证码，请注意查看'
              })
              this.can_get_code1 = false
              let n = 60
              const _timeer = setInterval(() => {
                n--
                this.can_get_code_nub1 = n
                if (n <= 0) {
                  clearInterval(_timeer)
                  this.can_get_code1 = true
                  this.can_get_code_nub1 = 60
                }
                // console.log(n)
              }, 1000)
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">

.login {
  width: 100%;
  background: #f9f9f9;
  min-height: 600px;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex: 3;
  .login-left {
    flex: 2;
    background: linear-gradient(135deg,#0f1a38, #0a1228 100%);
    border: 0.5px solid #707070;
    padding: 14px 0 48px 44px;
    position: relative;
    .img_one {
      width: 327px;
      height: 126px;
      background: url('../../assets/login/login1.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .img_two {
      margin: 20px 0 16px 4px;
      width: 60px;
      height: 12px;
      background: url('../../assets/login/login2.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .main-title {
      height: 123px;
      font-size: 40px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      color: #ffffff;
      line-height: 60px;
      letter-spacing: 0.45px;
    }
    .img_three {
      margin: 34px 0 0px -20px;
      width: 196px;
      height: 196px;
      background: url('../../assets/login/login3.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .main-bottom {
      position: absolute;
      bottom: 48px;
      display: flex;
      .img-four {
        width: 36px;
        height: 39px;
        background: url('../../assets/login/login4.png');
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .title {
        margin-left: 15px;
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        color: #ffffff;
        line-height: 39px;
      }
    }
  }

  .login-right {
    flex: 2;
    position: relative;

    .login-right-foot {
      position: absolute;

      left: 0;
      right: 0;
      bottom: 5px;
      margin: auto;
      width: 240px;
      height: 26px;
      font-size: 16px;
      font-weight: 400;
      color: #707070;
    }
  }

}

.login_title {
  width: 100%;
  height: 50px;
  position: absolute;
  top: 10px;
  left: 0;
}

.login_title img {
  float: left;
  margin-left: 50px;
  margin-right: 18px;
  width: 34px;
  height: 34px;
  padding-top: 8px;
}

.login_title p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 50px;
  color: rgba(255, 255, 255, 1);
}

.login_form_div {
  width: 500px;
  height: 425px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.12);
  /* opacity:0.12; */
  border-radius: 10px;
  z-index: 999;
  padding: 37px 52px;

}

.div_row_title {
  width: 100%;
  padding-top: 20px;
}

.div_row_title p {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 14px 0;
  color: rgba(39, 38, 54, 1);
}

.div_row_title span {
  width: 120px;
  margin: 0 auto;
  display: block;
  height: 4px;
  background-color: #5d88f7;
}

.div_row_title img {
  width: 180px;
  height: 45px;
  display: table-cell;
  margin: 0 auto;
}

.div_row {
  margin-top: 20px;
}

.div_bottom {
  width: 70%;
  margin: 0 auto;
  background: rgba(253, 241, 241, 1);
  border-top: 1px solid rgba(232, 232, 232, 1);
  padding-top: 10px;
  font-size: 14px;
  letter-spacing: 8px;
  font-weight: 400;
  text-align: center;
  color: rgba(200, 198, 198, 1);
}

.login_input {
}

.click_login_input {
  border-bottom: 2px solid #5d88f7;
}

.click_login_input .el-icon-name {
  background: url('../../assets/aImg/el-icon-names.png') no-repeat center;
  background-size: 100%;
}

.click_login_input .el-icon-psd {
  background: url('../../assets/aImg/el-icon-psds.png') no-repeat center;
  background-size: 100%;
}

/* .item_div {
  width: calc(100%);
  float: right;
}
 */
@media screen and (max-width: 600px) {
  .login-left{
    display: none;
  }
  .login_form_div {
    width: 75%;
    padding: 10px;
  }
  .login-right{
    background: url('../../assets/login_b.png') no-repeat;
  }
  .login_form_div{
    background: rgba(255,255,255,0.6);
  }
}
.login_div_top{
  font-size: 40px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #3e4772;
  line-height: 42px;
}
.form-item {
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  color: #3e4772;
  line-height: 21px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 2px;
}

.btn_login {
  background: #2e375d;
  border-radius: 7.5px;
  color: #ffffff;
}

.btn_yzm {
  background: #2e375d;
  border-radius: 7.5px;
  color: #ffffff;
}
</style>
<style lang="scss">
.login-page {

  .login_input .el-input__inner {
    border: 1px solid #d8dce5;
  }

  .login_input .el-form-item__error {
    color: #fa5555;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 50px;
  }

  .login_input .el-input {
    font-size: 12px;
  }

  .el-dialog {
    padding: 0 20px;
  }

  .login .el-dialog__header {
    background-color: #fff !important;
  }

  .login .el-dialog__title {
    color: #303133 !important;
    font-size: 18px !important;
  }

  .login .el-dialog__body {
    padding: 30px 20px;
    padding-bottom: 0 !important;
    color: #606266 !important;
    font-size: 14px !important;
    word-break: break-all !important;
  }

  .el-button {
    border-radius: 23px 24px 24px 25px;
  }

  .login_input {
    .el-form-item__content {
      height: 36px;
      line-height: 36px;
    }

    .el-input__icon {
      font-size: 20px;
      line-height: 36px;
    }

    input {
      border-radius: 25px;
      background: none !important;
      height: 36px;
      line-height: 36px;
      color: white;
    }

  }

  .el-input--prefix .el-input__inner {
    padding-left: 36px;
  }

  .el-input__prefix {
    padding-left: 4px;
  }

  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

}
.el-input {
  .el-input__inner {
    border: 1px solid #36416e;
    color: #2e375d;
  }
}
.imgVerifyArea{
  text-align: center;
}
</style>
